<template>
  <div style="width: 100%">
    <el-select
      :filterable="true"
      :remote="true"
      :remote-method="getData"
      v-model="data"
      @change="dataChange"
      value-key="Key"
      :clearable="clearable"
      @clear="dataChange"
      style="width: 100%"
      :disabled="disabled"
    >
      <el-option
        v-for="item in datas"
        :key="item.Key"
        :label="item.FullName"
        :value="item"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { searchRegion } from "@/api/region";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      default: "",
    },
    clearable: {
      default: true,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      datas: [],
      data: "",
    };
  },
  watch: {
    value: {
      handler(v) {
        this.data = v;
        if (this.data && this.data.Key) {
          let findOjb = this.datas.find((r) => r.Key == this.data.Key);
          if (!findOjb) {
            this.datas.push(this.data);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getData(search) {
      let param = {
        search: search,
      };
      searchRegion(param).then((res) => {
        if (res) {
          this.datas = res.Items;
        }
      });
    },
    dataChange(v) {
      this.$emit("change", v);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style>
.el-select-dropdown__item {
  height: auto !important;
}
</style>
<style lang='scss' scoped>
.proItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .name {
  }
  .companys {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .company {
      font-size: 10px;
      line-height: 16px;
      color: silver;
      margin-bottom: 10px;
      font-weight: normal;
    }
  }
}
</style>