import request from '@/utils/request'

export function getRegions(data) {
  return request({
    url: 'region/GetRegions',
    method: 'post',
    data
  })
}

export function getRegionBySearch(data) {
  return request({
    url: 'region/GetRegionBySearch',
    method: 'post',
    data
  })
}

export function getRegionByCode(data) {
  return request({
    url: 'region/GetRegionByCode',
    method: 'post',
    data
  })
}

export function getRegionByParent(data) {
  return request({
    url: 'region/GetRegionByParent',
    method: 'post',
    data
  })
}

export function SaveRegion(data) {
  return request({
    url: 'region/save',
    method: 'post',
    data
  })
}

export function DeleteRegion(data) {
  return request({
    url: 'region/delete',
    method: 'post',
    data
  })
}

export function GetRegionParents(data) {
  return request({
    url: 'region/GetRegionParents',
    method: 'post',
    data
  })
}

export function searchRegion(data) {
  return request({
    url: 'region/searchRegion',
    method: 'post',
    data
  })
}

