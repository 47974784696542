import { render, staticRenderFns } from "./regionSelector2.vue?vue&type=template&id=78dbae3f&scoped=true&"
import script from "./regionSelector2.vue?vue&type=script&lang=js&"
export * from "./regionSelector2.vue?vue&type=script&lang=js&"
import style0 from "./regionSelector2.vue?vue&type=style&index=0&lang=css&"
import style1 from "./regionSelector2.vue?vue&type=style&index=1&id=78dbae3f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78dbae3f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\source\\200xth\\xth2.web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('78dbae3f')) {
      api.createRecord('78dbae3f', component.options)
    } else {
      api.reload('78dbae3f', component.options)
    }
    module.hot.accept("./regionSelector2.vue?vue&type=template&id=78dbae3f&scoped=true&", function () {
      api.rerender('78dbae3f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/regionSelector2.vue"
export default component.exports